<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <!-- TODO Fancy Welcome Design -->
        <p>In the next few steps, we'll guide you through the main ContainerSSH settings and generate the configuration you need to get it up and running.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script type="ts">
export default {
  name: 'Welcome',
  props: {
    configuration: Object
  },
  methods: {},
  data: () => ({

  })
}
</script>